import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Plantationgridregister from 'components/register-plantation/plantationgrid';

// markup
const Plantationgrid = () => {
  // const dataValue = (location.state && location.state.registerData !== undefined) ? location.state.registerData : null;
  return (
    <>
      <SEO title='Forests By Heartfulness | plantation grid' />
      <Layout>
        <section id='plantationgrid'>
          <Plantationgridregister
          />
        </section>
      </Layout>
    </>
  );
};

export default Plantationgrid;
